<template>
  <div class="d-flex justify-content-center">
    <b-row :class="`mt-3 ${has_slug ? 'col-12 col-sm-10' : 'col-12 col-lg-8 col-xl-5'}`">
      <b-col class="col-12 text-center">
        <h1 class="mb-1">{{ $t('creator.compensation?') }}</h1>
        <p>{{ $t('creator.compensationText') }}</p>

        <b-button-group class="w-100 mt-1 mb-2">
          <b-button 
            @click="tabs_options = 0" 
            :class="{'active': tabs_options === 0}" 
            class="button-tabs-options mr-1" 
            variant="button-tabs-options"
            
          >
            {{$t('creator.negotiableOnly')}}
          </b-button>
          <b-button 
            @click="tabs_options = 1" 
            :class="{'active': tabs_options === 1}" 
            class="button-tabs-options" 
            variant="button-tabs-options"
          >{{$t('creator.fixedOnly')}}</b-button>
        </b-button-group> 
        <div v-if="tabs_options === 0">
          <p>
            {{$t('creator.compensationLastText')}}
          </p>
        </div>

        <div v-if="tabs_options === 1">
          <strong class="text-danger col-12 mt-2 h4 d-block" v-if="show_asset_type_error">{{ $t('creator.amount0') }}</strong>
          <div class="position-relative">
  
            <b-dropdown class="w-100 mt-2" menu-class="w-100" variant="outline-secondary" :disabled="compensations.length > 4">
              <template #button-content>
                {{ $t('creator.addCompensation') }}
              </template>
              <b-dropdown-item v-for="(item) in compensations_availble" :key="item.value" @click="addCompensationType(item)">
                <div @mouseover="hovered_item = item" @mouseout="hovered_item = null">
                  <div class="d-flex align-items-center">
                    <b-icon v-if="getCompensation(item.value).icon && item !== selected_compensation"
                            :icon="getCompensation(item.value).icon"
                            class="mr-05"></b-icon>
                    <b-img v-else class="icon-svg mr-05"
                          :src="getImageSource(item)"></b-img>
                    <div>
                      <strong>{{item.text}}</strong>
                      <p class="avenir-medium m-0 text-description">{{item.description}}</p>
                    </div>
                  </div>
                </div>
              </b-dropdown-item>
            </b-dropdown>
            <div v-for="compensation in compensations" :key="compensation.value" class="d-flex justify-content-between">
              <div class="d-flex align-items-center border-bottom py-2">
                <b-icon v-if="getCompensation(compensation.value).icon" :icon="getCompensation(compensation.value).icon" class="mr-05"></b-icon>
                  <b-img v-else class="icon-svg mr-05" :src="IconTypeCompetation(compensation.value)"></b-img>
                <strong>{{compensation.text2}}</strong>
              </div>
              <div class="d-flex align-items-center">
                <div>
                  <b-input-group @keyup.enter="compensation.amount.length > 0 ? save(): ''">
                    <b-input-group-prepend is-text>
                      <feather-icon
                        icon="DollarSignIcon"
                        class="text-muted"
                      />
                    </b-input-group-prepend>
                    <cleave
                      v-model="compensation.amount"
                      :options="options_number"
                      class="form-control"
                      :raw="false"
                    />
                  </b-input-group>
                </div>
                <b-button variant="flat-secondary" class="flat-secondary-delete-links" @click="deleteFromList(compensation)">
                  <feather-icon icon="TrashIcon"></feather-icon>
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <b-col class="col-12 d-flex justify-content-center">
          <b-button variant="blue-button-next-step" class="blue-button-next-step my-2" :disabled="tabs_options === 1 && compensations.length === 0" @click="save()">{{has_slug ? $t('creator.savePreview') : $t('creator.butonNext')}}</b-button>
        </b-col>
      </b-col>
      <small class="mt-2 w-100 text-center text-muted" v-if="tabs_options === 1">{{ $t('creator.compensationRequired') }}</small>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  BInputGroup,
  BInputGroupPrepend,
  BImg,
  BIcon,
  BButtonGroup,
} from 'bootstrap-vue';
import utils from '@/libs/utils';
import casting_calls_services from '@/services/casting_calls';
import Cleave from 'vue-cleave-component';
import { IconTypeCompetation, IconTypeCompetationPrimary } from '@/libs/utils/icons';

export default {
  name: 'stepCompensation',
  components: {
    BIcon,
    BImg,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BInputGroup,
    BInputGroupPrepend,
    BButtonGroup,
    Cleave,
  },
  data() {
    return {
      tabs_options: 0,
      selected_compensation: null,
      hovered_item: null,
      IconTypeCompetationPrimary,
      IconTypeCompetation,
      compensations: [],
      compensations_availble: this.$t('creator.compensation_type'),
      profile: {},
      utils,
      steps: null,
      casting_call: {},
      casting_call_uuid: '',
      show_dropdown: false,
      tag: '',
      show_asset_type_error: false,
      options_number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand'
      },
    }
  },
  created() {
    this.getData();
  },
  computed: {
    has_slug() {
      return this.$route.params.slug
    }
  },
  methods: {
    getImageSource(item) {
      if (item === this.selected_compensation) {
        return IconTypeCompetation(item.value);
      } else if (item === this.hovered_item) {
        return IconTypeCompetationPrimary(item.value);
      } else {
        return IconTypeCompetation(item.value);
      }
    },
    getCompensation(compensation_value) {
      return this.$t('creator.compensation_type').find((c) => c.value === compensation_value)
    },
    deleteFromList(compensation) {
      if (compensation.uuid) {
        casting_calls_services.deleteCompensation(this.casting_call_uuid, compensation.uuid);
      }
      this.compensations = this.compensations.filter((f) => f.value !== compensation.value);
      this.compensations_availble.push(this.$t('creator.compensation_type').find((item) => item.value === compensation.value))      
    },
    getCompensationFormatData() {
      this.casting_call.compensations.forEach((compensation) => {
        this.compensations.push({
          amount: parseInt(compensation.amount),
          ...this.$t('creator.compensation_type').find((item) => item.value === compensation.type_compensation),
          uuid: compensation.uuid
        });
        this.compensations_availble = this.compensations_availble.filter((item) => item.value !== compensation.type_compensation)
      });
    },
    addCompensationType(compensation) {
      compensation['amount'] = null;
      this.compensations.push(compensation)
      this.compensations_availble = this.compensations_availble.filter((item) => item.value !== compensation.value)
    },
    getData() {
      if (this.has_slug) {
        this.casting_call = this.$route.params.casting_call_data;
        this.casting_call_uuid = this.casting_call.uuid;
        if (this.casting_call.type_compensation === 'negotiable') {
          this.tabs_options = 0
        } else {
          this.tabs_options = 1;
          this.getCompensationFormatData();
        }
      } else {
        this.steps = JSON.parse(localStorage.getItem('steps'));
        this.casting_call_uuid = this.steps.uuid ? this.steps.uuid : this.$route.params.casting_call_uuid
        casting_calls_services.getCastingCall(this.casting_call_uuid).then((response) => {
          this.casting_call = response;
          this.getCompensationFormatData();
        });
      }
    },
    hasValueCompensations() {
      let counter = 0;
      this.compensations.forEach((compensation) => {
        if (compensation.amount !== null && compensation.amount !== 0) counter += 1;
      })
      counter !== this.compensations.length ? this.show_asset_type_error = true : this.show_asset_type_error = false
      return counter === this.compensations.length
    },
    save() {
      this.updateTypeCompensation();
    },
    updateTypeCompensation() {
      const form_data = new FormData();
      form_data.append('type_compensation', this.tabs_options === 0 ? 'negotiable' : 'fixed')
      casting_calls_services.updateCastingCall(this.casting_call_uuid, form_data).then((response) => {
        if (this.tabs_options === 0 && this.has_slug) {
          this.$emit('update_campaign', response.response);
        } else if (this.tabs_options === 0 && !this.has_slug) {
          this.$router.push({ name: 'steps_casting_call', params: {step: 'information-request'}})
        } else if (this.hasValueCompensations() && this.tabs_options === 1) {
          const formated_data = this.compensations.map((item) => {
            return {
              type_compensation: item.value,
              amount: parseInt(item.amount.split(',').join('')),
              uuid: item.uuid,
            }
          });
          const compensation_to_update = formated_data.filter((compensation_filter) => compensation_filter.uuid);
          const compensation_to_create = formated_data.filter((compensation_filter) => !compensation_filter.uuid);
          casting_calls_services.attatchCompensations(this.casting_call_uuid, {compensations: compensation_to_create}).then((response_create) => {
            if (compensation_to_create.length > 0) this.$emit('update_campaign', response_create);
            casting_calls_services.updateCompensations(this.casting_call_uuid, {compensations: compensation_to_update}).then((response_children) => {
              if (!this.has_slug) this.$router.push({ name: 'steps_casting_call', params: {step: 'information-request'}})
              else this.$emit('update_campaign', response_children);
            })
          })
        }
      })
    }
  }
}
</script>
<style>
.icon-svg {
  width: 18px;
}
.text-description {
  white-space: break-spaces;
}
.dropdown-add-tags .btn {
  width:  100% !important;
  padding: 0 !important;
}
</style>
<style scoped lang="scss">
.button-tabs-options {
  background: #e9ecef !important;
  border-radius: 0.7em !important;
  padding: 1.5em !important;

  &:hover  {
    background: #dee2e6 !important;

    &:disabled {
      background: #e9ecef !important;
    }
  }

  &.active {
    background-color: #54575a !important;
    color: white !important
  }
}
.flat-secondary-delete-links {
  padding: 0.5em !important;
  height: 35px !important;
  margin-left: 1em;
}
.mr-05 {
  margin-right: 0.5em;
}
</style>
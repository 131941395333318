<template>
  <div class="d-flex justify-content-center">
    <b-row :class="`mt-3 ${has_slug ? 'col-12 col-sm-10' : 'col-12 col-lg-8 col-xl-5'}`">
      <b-col class="col-12 text-center">
        <h1 class="mb-1">{{ $t('creator.lastStep') }}</h1>
        <p>{{ $t('creator.thisHelp') }}</p>
        <b-button v-if="requests.length <= 1" variant="flat-secondary" class="my-2 p-2" @click="addRequest" :disabled="requests.length > 3">
          <feather-icon icon="PlusIcon"></feather-icon>  
          {{ $t('creator.addRequest') }}
        </b-button>
        <div class="d-flex align-items-center justify-content-center">
          <b-pagination
            pills
            v-model="current_page"
            :total-rows="requests.length"
            :per-page="per_page"
            class="justify-content-center pt-1"
            hide-goto-end-buttons
            next-class="d-none"
            prev-class="d-none"
            v-if="requests.length > 1"
          ></b-pagination>

          <b-button v-if="requests.length > 1 && requests.length !== 4" variant="blue-button-next-step" class="ml-1 d-flex blue-button-next-step p-05" @click="addRequest" :disabled="requests.length > 3">
            <b-icon icon="plus" font-scale = "2"/>  
          </b-button>
        </div>
        <div v-for="(request, index) in requestsFiltered()" :key="index">
          <div class="text-danger text-right cursor-pointer" @click="deleteRequest(request)" v-if="requests.length > 0">{{ $t('delete') }}</div>
          <b-form-group :label="$t('creator.questionCall')"  label-for="input-description" class="form-group-layout">
            <b-form-textarea id="input-description" v-model="request.question" rows="3"></b-form-textarea>
          </b-form-group>
          <div class="bg-white p-2" v-if="request.type_question === 'radio' || request.type_question === 'checkbox'">
            <b-form-group :label="`${$t('creator.optionCall')} ${index_options + 1}`" label-for="input-description" v-for="(i, index_options) in request.options" :key="index_options" class="form-group-layout">
              <div class="d-flex">
                <b-form-input id="input-description" v-model="request.options[index_options]" rows="3"></b-form-input>
                <b-button 
                  v-if="index_options !== 0 && index_options !== 1" 
                  class="p-0 p-05 ml-1" 
                  variant="flat-secondary"
                  @click="deleteOption(index_options, current_page - 1)"
                >
                <feather-icon icon="TrashIcon" size="18" ></feather-icon></b-button>
              </div>
            </b-form-group>
            <b-button variant="primary" @click="addOption(current_page - 1)">
              <feather-icon icon="PlusIcon"></feather-icon>
              {{ $t('creator.addOption') }}</b-button>
          </div>

          <b-form-group :label="$t('creator.responseType')" label-for="input-delivery" class="mt-2 form-group-layout">
          <component :is="vue_select" label="text" :options="$t('creator.request_type')" id="input-type-question" v-model="request.type_question" @input="typeOption(request)">
            <template #option="{text, value, long_text}">
              <div class="d-flex align-items-center">
                <div>
                  <b-icon :icon="getIconsRequest(value)" class="mr-1"></b-icon>
                </div>
                <div class="d-flex flex-column">
                  <span class="d-block">{{text}}</span>
                  <span class="avenir-medium text-option">{{long_text}}</span>
                </div>
              </div>
            </template>
            <template #selected-option="{ text }">
              <div>
                {{ $t('creator.request_type').find(option => option.value === text).text }}
              </div>
            </template>
          </component>
        </b-form-group>
          <div class="d-flex justify-content-between mt-2">
            <span class="label-checkbox">{{ $t('creator.questionRequired') }}</span>
            <b-form-checkbox v-model="request.required_question" switch>
            </b-form-checkbox>
          </div>
        </div>
        <strong class="text-danger d-block col-12 mt-2 h4" v-if="!validation">{{text_warning}}</strong>
        <b-col class="col-12 d-flex justify-content-center">
          <b-button variant="blue-button-next-step" class="blue-button-next-step my-3" @click="save()">{{ $t('creator.saveSeePreview') }}</b-button>
        </b-col>
        <small class="mt-2 w-100 text-center text-muted">{{ $t('creator.optionalQuestion') }}</small>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BIcon,
  BPagination,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue';
import utils from '@/libs/utils';
import casting_calls_services from '@/services/casting_calls'

export default {
  name: 'stepInformationRequest',
  components: {
    BRow,   
    BCol,
    BButton,
    BFormTextarea,
    BIcon,
    BPagination,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
  },
  data() {
    return {
      utils,
      vue_select: null,
      steps: null,
      requests: [],
      options_number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand'
      },
      current_page: 1,
      per_page: 1,
      validation: true,
      text_warning: '',
      casting_call: {},
      option: this.$t('creator.request_type'),
    }
  },
  computed: {
    has_slug() {
      return this.$route.params.slug;
    }
  },
  created() {
    this.getData();
  },
  async mounted() {
    this.vue_select = (await import('vue-select')).default;
  },
  methods: {
    typeOption(request) {
      const currentPageIndex = this.current_page - 1;
      const currentOptionsLength = this.requests[currentPageIndex].options.length;

      const typeValue = request.type_question.value;
      request.type_question = typeValue;

      if (typeValue === 'radio' || typeValue === 'checkbox') {
        if (currentOptionsLength < 2) {
          this.addOption(currentPageIndex);
        }
      } else {
        request.options = [];
      }
    },


    getIconsRequest(type) {
      if (type === 'free_text') return 'textarea-t';
      if (type === 'radio') return 'ui-radios';
      if (type === 'checkbox') return 'ui-checks';
      if (type === 'media') return 'file-earmark-arrow-up';
    },
    requestsFiltered () {
      const options = this.requests;
      return options.slice((this.current_page - 1) * this.per_page, this.current_page * this.per_page);
    },
    getData() {
      if (this.has_slug) {
        this.casting_call = {...this.$route.params.casting_call_data};
        this.requests = this.casting_call.questions.slice();
      } else {
        this.steps = JSON.parse(localStorage.getItem('steps'));
        this.casting_call_uuid = this.steps.uuid ? this.steps.uuid : this.$route.params.casting_call_uuid
        casting_calls_services.getCastingCall(this.casting_call_uuid).then((response) => {
          this.casting_call = response;
          this.requests = this.casting_call.questions;
        });
      }
    },
    save() {
      this.requests.map((request) => {
        if (request.type_question === 'radio' || request.type_question === 'checkbox') {
          if (request.options.length < 2) {
            this.validation = false;
            this.text_warning = `${this.$t('creator.2options')}"${request.question}"`;
          } else {
            this.validation = true;
          }
          request.remove_options = false;
        } else {
          this.validation = true;
          request.remove_options = true;
        }
      });

      if (this.validation) {
        const questions_to_update = this.requests.filter((request) => request.uuid);
        const questions_to_create = this.requests.filter((request) => !request.uuid);
        casting_calls_services.attatchQuestions(this.casting_call.uuid, { questions: questions_to_create }).then((response_create) => {
          if (questions_to_create.length > 0) this.$emit('update_campaign', response_create);
          casting_calls_services.updateQuestions(this.casting_call.uuid, { questions: questions_to_update }).then((response_update) => {
            if (!this.has_slug) { 
              this.$router.push({ name: 'view_casting_call', params: { slug: this.casting_call.slug } });
              localStorage.removeItem('steps');
              this.$root.$emit('quit_navbar_white');
            }
            this.$emit('update_campaign', response_update);
          });
        });
      }
    },

    addRequest() {
      if (this.requests.length < 4) {
        this.requests.push({
          index: Math.random().toString(36).substr(2, 18),
          question: this.$t('creator.anyQuestion'),
          type_question: 'free_text',
          required_question: false,
          options: []
        });
        const newPageIndex = Math.ceil(this.requests.length / this.per_page);
        setTimeout(() => {
          this.current_page = newPageIndex;
        }, 10);
      }
    },
    deleteRequest(request) {
      if (request.uuid) {
        casting_calls_services.deleteQuestions(this.casting_call.uuid, request.uuid).then(() => {
          this.requests = this.requests.filter((item) => item.uuid !== request.uuid);
        });
      } else {
        this.requests = this.requests.filter((item) => item.index !== request.index);
      }
      this.current_page = this.requests.length === 1 ? this.requests.length : this.requests.length - 1;
    },
    addOption(index_father) {
      const currentOptionsLength = this.requests[index_father].options.length;

      if (currentOptionsLength === 0) {
        this.requests[index_father].options.push(
          `${this.$t('creator.optionCall')} ${currentOptionsLength + 1}`,
          `${this.$t('creator.optionCall')} ${currentOptionsLength + 2}`
        );
      } else {
        this.requests[index_father].options.push(`${this.$t('creator.optionCall')} ${currentOptionsLength + 1}`);
      }
    },


    deleteOption(index_option, index_father) {
      this.requests[index_father].options = this.requests[index_father].options.filter((option, index) => index !== index_option)
    }
  },
}
</script>
<style>
.text-option {
  white-space:break-spaces
}
.img-value {
  width: 24px;
  margin-right: 5px;
}
.form-group-layout > label {
  text-align: left;
}
.label-checkbox {
  color: #5e5873;
  font-size: 0.857rem;
}
.p-05 {
  padding: 0.5em !important;
}
#input-type-question {
  background-color: white;
}
#input-type-question .vs__clear {
  display: none;
}
</style>